<template>
  <div :style="slidePosition" class="h-100 z-10 slide0Wrapper" style="width:100%;">
    <div class="position-absolute yellow-arrow" style="right:3%;top:50%;transform:translateY(-50%);">
      <inline-svg src="images/icons/arrow-next.svg" class="my-5 " aria-label="continue" style="width:2vw;" />
    </div>
    <div
      class="position-absolute d-flex flex-column justify-content-center align-items-center p-4 w-100 h-100 text-white text-center"
      style="p-4">
      <inline-svg src="images/triumf-logo.svg" class="" aria-label="TRIUMF LOGO" style="width:70%;max-width:300px;" />
      <h1 class="font-vw-2 text0 " style="opacity:1; width:70%;margin-bottom:6%;margin-top:5%;">
        USE ANY OF THE FOLLOWING NAVIGATION METHODS
      </h1>
      <div class="d-flex w-70 mx-auto justify-content-center" style="opacity:1; width:70%;margin-bottom:3%;">
        <div class="w-30 d-flex flex-column align-items-center justify-content-center">
          <inline-svg src="images/icons/swipe-icon.svg" class="" aria-label="Swipe icon" style="height:9vh;" />
        </div>
        <div class="w-30 d-flex flex-column align-items-center justify-content-center">
          <inline-svg src="images/icons/scroll-icon.svg" class="" aria-label="Scroll icon" style="height:12vh;" />
        </div>
        <div class="w-30 d-flex flex-column align-items-center justify-content-center ">
          <div class="d-flex">
            <inline-svg src="images/icons/key-left.svg" class="mr-2" aria-label="Key icon" style="height:8vh;" />
            <inline-svg src="images/icons/key-right.svg" class="" aria-label="Key icon" style="height:8vh;" />
          </div>
        </div>
      </div>

      <div class="d-flex w-70 mx-auto justify-content-center">
        <div class="w-30 d-flex flex-column align-items-center justify-content-center">
          <h5 class="font-vw-1-5 text0">Swipe left-right</h5>
        </div>
        <div class="w-30 d-flex flex-column align-items-center justify-content-center">
          <h5 class="font-vw-1-5 text0">Scroll wheel</h5>
        </div>
        <div class="w-30 d-flex flex-column align-items-center justify-content-center ">
          <h5 class="font-vw-1-5 text0">Arrow keys</h5>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import anime from "animejs"
import { normalize } from "@/utils/ranges"
import { slidePositionPlain } from "@/utils/slidePositioning"
export default {
  props: ["globalUnits", "slideIndex"],
  computed: {
    slidePosition() {
      //console.log("SLIDES INDEX", this.slideIndex)
      return slidePositionPlain(this.globalUnits, this.slideIndex)
    },
    innerPosition() {
      return (
        (this.globalUnits - this.slideIndex.start) /
        (this.slideIndex.end - this.slideIndex.start)
      )
    }
  },
  mounted() {
    this.startLoop()
  },
  methods: {
    startLoop() {
      anime({
        loop: true,
        targets: ".yellow-arrow",
        translateX: ["0%", "-100%"],
        direction: "alternate",
        duration: 600,
        easing: "easeInOutSine"
      })
    },
    slide0(percentage) {
      let animation = anime.timeline({
        autoplay: false,
        loop: false,
        endDelay: 500
      })

      animation
        .add({
          targets: ".fade0",

          translateY: ["-30%", "0%"],
          opacity: [0, 1],
          duration: 100,
          easing: "easeInOutSine",
          delay: (el, i) => 5000 + 30 * i
        })
        .add({
          targets: ".text0",

          opacity: [0, 1],

          translateY: ["35%", "0%"],
          duration: 100,
          easing: "easeInOutSine"
        })
      var progress =
        percentage <= 0.5 ? percentage * 2 : normalize(percentage, 0.5, 1, 1, 0)
      animation.seek(animation.duration * progress)
    }
  },
  watch: {
    innerPosition() {
      //this.slide0(this.innerPosition)
    }
  }
}
</script>
<style lang="scss" scoped></style>
